@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

$Ubuntu: 'Ubuntu', sans-serif;
$Inter: 'Inter';
$Roboto: 'Roboto';
$lineColor: #dee2ef;
$mainText: #484b54;
$darkText: #303239;
$secondaryDark: #263a54;
$blackText: #232428;
$secondaryText: #a1a6b3;
$currencyColor: #757985;
$successColor: #32d583;
$errorText: #b42318;
$borderColor: #f79009;

@mixin mainText {
  text-align: start;
  font-family: $Ubuntu;
  font-style: normal;
  font-weight: 400;
}

@mixin mainSize {
  font-size: 16px;
  line-height: 28px;
}

@mixin smallSize {
  font-size: 14px;
  line-height: 24px;
}

@mixin smallerSize {
  font-size: 12px;
  line-height: 20px;
}

@mixin largeSize {
  font-size: 20px;
  line-height: 32px;
}

@mixin position {
  position: relative;
  z-index: 5;
}

@mixin container {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
}

@mixin headerItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #eceff5;
  padding: 4px 12px;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(180deg, #cacedb 0%, #ffffff 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

@mixin leftPadding {
  margin-left: 112px;
  @media (max-width: 1350px) {
    margin-left: 0;
  }
}

@mixin blockStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 56px;
  position: relative;
  width: 632px;
  height: 100%;
  box-sizing: border-box;
  /* Gray/00 */

  background: #ffffff;
  box-shadow: 0px 1.7328px 5.19839px rgba(16, 24, 40, 0.1),
    0px 1.7328px 3.46559px rgba(16, 24, 40, 0.06),
    inset 1.7328px 1.7328px 1.7328px #ffffff;
  backdrop-filter: blur(14px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;
}

@mixin bgText {
  position: absolute;
  @include mainText;
  font-weight: 700;
  font-size: 141.493px;
  line-height: 120px;

  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: 1100px) {
    font-size: 120px;
    line-height: 138px;
  }
  @media (max-width: 740px) {
    font-size: 56px;
    line-height: 80px;
  }
}

@mixin secondaryText {
  position: absolute;
  @include mainText;
  @include smallSize;
  font-weight: 700;
  text-transform: uppercase;
  color: #a1a6b3;
  transform: rotate(-90deg);
  transform-origin: left bottom;
}

@mixin colorTransition {
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

@mixin scroll {
  &::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #616774;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #484b54;
    border-radius: 10px;
  }
}
