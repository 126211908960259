@use 'src/styles/globals.scss' as style;

.appsBlock {
  width: 137px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include style.position;
}

.logo {
  height: 24px;
}

.appsItem {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  width: 137px;
  height: 32px;
  box-sizing: border-box;
  text-decoration: none;
  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(0, 16, 47, 0.15);
  border-radius: 4px;
  span {
    @include style.mainText;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: style.$blackText;
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  &:hover {
    cursor: pointer;
    box-shadow: -2px -2px 12px #ffffff, 2px 2px 4px rgba(0, 16, 47, 0.19);
  }
  &:active {
    background: #f4f6fb;
    box-shadow: inset -4px -4px 4px #ffffff,
      inset 2px 2px 4px rgba(0, 0, 0, 0.07);
  }
}
