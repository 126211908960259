@use "src/styles/globals.scss" as style;

.containerRate{
  margin-left: 9px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include style.mainText;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: style.$currencyColor;
  margin-bottom: 8px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.summary {
  width: 220px;
  text-align: start;
  @include style.mainText;
  @include style.smallSize;
  span {
    font-weight: 700;
  }
}

.item .rate {
  @include style.mainText;
  @include style.smallSize;
  font-weight: 700;
  color: #F79009;
}
