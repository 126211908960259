@use 'src/styles/globals.scss' as style;

.container {
  @include style.leftPadding;
  position: relative;
  padding-bottom: 190px;

}
.wrapper {

  @include style.position;
  width: 100%;
  height: 100%;
}

.secondText {
  margin-left: 286px;
  @include style.mainText;
  @include style.smallSize;
  @include style.position;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #a1a6b3;
}

.bgText {
  left: 24px;
  top: 250px;
  @include style.secondaryText;
}

.benefitBlock {
  overflow: hidden;
  margin-top: 50px;
  margin-left: 64px;
  box-sizing: border-box;
  width: 1152px;
  // height: 560px;
  padding: 56px 40px;
  background: rgba(250, 251, 255, 0.55);
  box-shadow: 0px 1.7328px 5.19839px rgba(16, 24, 40, 0.1),
    0px 1.7328px 3.46559px rgba(16, 24, 40, 0.06),
    inset 1.7328px 1.7328px 1.7328px #ffffff;
  backdrop-filter: blur(6px);

  border-radius: 24px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.background {
  background: url('../../assets/images/noise.png'), rgba(250,251,255,.22);;
  height: 100%;
  left: 0;
  opacity: .04;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.logo {
  margin-bottom: 28px;
}

.line {
  position: absolute;
  height: 100%;
  border-left: 1px solid #FFFFFF;
  border-right: 1px solid #CACEDB;
  top: 0;
  left: 50%;
  z-index: 5;
}

.benefit.second {
  box-sizing: border-box;
  padding-left: 40px;
  .benefit__item {
    &:last-child p, &:nth-child(-n + 5) p {
      color: #B42318;
    }
  }
}

.benefit {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @include style.position;
  .header {
    margin: 0;
    width: 80px;
    padding-bottom: 28px;
    @include style.mainText;
    @include style.smallSize;
    color: style.$blackText;
    font-weight: 700;
  }
  &__item{
    &:not(:first-child) {
      margin-top: 12px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      @include style.mainText;
      font-size: 16px;
      line-height: 24px;
      color: style.$blackText;
    }
    span {
      font-size: 16px;
      line-height: 24px;
      color: #A1A6B3;
    }
  }
}

.check {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  background: linear-gradient(212.01deg, #FFFFFF -12.82%, #CACEDB 80.77%);

  box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1), 0px 2px 4px rgba(16, 24, 40, 0.06);
  border-radius: 40px;
  margin-right: 12px;
}

.checkBg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #F4F6FB;
}

@media (max-width: 1280px) {
  .benefitBlock {
    width: 100%;
    height: 100%;
    margin-left: 0;
    padding: 36px 20px;
  }
  .bgText {
    display: none;
  }
}

@media (max-width: 960px){
  .container {
    padding-bottom: 80px;
  }
  .wrapper {
    overflow-x: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .benefitBlock {
    width: 890px;
    margin-bottom: 10px;
  }
  .secondText {
    margin-left: 0;
  }
}

@media (max-width: 550px){
  .secondText {
    display: none;
  }
}
