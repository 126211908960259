@use 'src/styles/globals.scss' as style;

.advantageBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  @include style.leftPadding;
}

.advantages {
  @include style.blockStyle;
  @include style.position;
  max-height: 920px;
  overflow: hidden;
  padding: 24px 56px 56px;
  margin-top: 48px;
  margin-bottom: 200px;
}

.bgText {
  top: 36px;
  left: 298px;
  @include style.bgText;
}

.bgTextAnimate{
  animation: bgTextPosition 2s ease-in;
}

.itemAnimate {
  animation: itemPosition 1s ease-in;
}

.secondaryText, .secondaryTextMobile {
  z-index: 5;
  right: -430px;
  top: 510px;
  @include style.secondaryText;
}

.secondaryTextMobile {
  display: none;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  &:not(:first-child) {
    margin-top: 32px;
  }
  p {
    margin: 0;
    padding-left: 16px;
    @include style.mainText;
    @include style.mainText;
    font-size: 16px;
    line-height: 24px;
    color: style.$blackText;
  }
}

@keyframes bgTextPosition{
  0% {
    transform: translateX(-70px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes itemPosition{
  0% {
    transform: translateY(20px);
    padding-top: 50px;
  }
  100% {
    transform: translateY(0px);
    padding-top: 0;
  }
}

@media (max-width: 1100px) {
  .bgText {
    left: 106px;
    top: 20px;
  }
  .advantages {
    margin-bottom: 124px;
  }
}

@media (max-width: 960px) {
  .secondaryText {
    display: none;
  }
  .secondaryTextMobile {
    display: block;
    right: -258px;
  }
}

@media (max-width: 740px) {
  .bgTextAnimate, .itemAnimate {
    animation: none;
  }
  .bgText {
    left: 70px;
  }
  .advantages {
    width: 100%;
    margin-top: 40px;
    padding: 24px 16px;
  }
  .secondaryTextMobile {
    display: none;
  }
  .advantages {
    max-height: 100%;
  }
}

@media (max-width: 480px) {
  .advantages {
    margin-bottom: 64px;
  }
  .bgText {
    top: 57px;
  }
}
