@use 'src/styles/globals.scss' as style;

.mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 493px;
  @include style.position;
  .modileImg {
    width: 217px;
    margin-right: 36px;
  }
}

@media (max-width: 960px) {
  .mobile .modileImg {
    width: 226px;
    margin-right: 135px;
    margin-left: 72px;
  }
  .mobileLinks {
    align-self: flex-start;
    margin-top: 120px;
  }
}

@media (max-width: 760px) {
  .mobile {
    margin-top: 150px;
    width: 100%;
    justify-content: flex-start;
  }
  .mobile .modileImg {
    width: 170px;
    margin-right: 57px;
    margin-left: -57px;
  }
  .mobileLinks {
    margin-top: 60px;
  }
}

@media (max-width: 350px) {
  .mobile .modileImg {
    width: 170px;
    margin-right: 35px;
  }
}
