@use 'src/styles/globals.scss' as style;

.worksBlock {
  @include style.position;
  margin-left: 494px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: -247px;
}

.works {
  margin-top: 79px;
  margin-right: 32px;
}

.worksHeader {
  margin: 0;
  @include style.mainText;
  @include style.smallSize;
  font-weight: 700;
  text-transform: uppercase;
  color: style.$blackText;
  padding-bottom: 10px;
}

.aboutWork {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.point {
  position: relative;
  padding: 8px 0px;
  width: 286px;
  height: 64px;
  border-top: 1px dashed #cacedb;
  margin-bottom: 32px;
  margin-left: 6px;
  p {
    margin: 0;
    @include style.mainText;
    @include style.smallSize;
    color: style.$blackText;
  }
}

.indexBlock {
  padding: 4px 0;
  position: absolute;
  left: -11px;
  top: -9.5px;
  background: #f4f6fb;
}

.indexPoint {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11px;
  height: 11px;
  background: #f4f6fb;
  border: 1px solid #a1a6b3;
  border-radius: 50%;

  @include style.mainText;
  font-size: 8px;
  line-height: 5px;

  color: #757985;
}

.imageBlock {
  background: #F4F6FB;
}

.workImage {
  width: 298px;
  height: 615px;
  mix-blend-mode: multiply;
}

@media (max-width: 1150px) {
  .worksBlock {
    margin-top: 80px;
    margin-left: 0;
  }
  .works {
    margin-top: 35px;
    margin-right: 205px;
  }
}

@media (max-width: 960px) {
  .works {
    margin-right: 103px;
  }
  .workImage {
    width: 252px;
    height: 521px;
  }
}

@media (max-width: 700px) {
  .worksBlock {
    margin-top: 64px;
  }
  .works {
    margin-top: 0;
    margin-right: 10px;
  }
}

@media (max-width: 610px) {
  .workImage {
    display: none;
  }
}

@media (max-width: 375px) {
  .point {
    width: 100%;
  }
}

