@use 'src/styles/globals.scss' as style;

.headerBlock {
  border-left: 2px solid style.$borderColor;
  padding-left: 8px;
  width: 100%;
  box-sizing: border-box;
  @include style.position;
  p {
    margin: 0;
    @include style.mainText;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: style.$mainText;
  }
}

@media (max-width: 740px) {
  .headerBlock p {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
}
