@use 'src/styles/globals.scss' as style;

.faunderBlock {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 54px;
}

.logo_x11 {
  position: absolute;
  width: 523px;
  bottom: -187px;
  left: -34px;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
  @include style.position;
  &__item {
    width: 32px;
    height: 32px;
    margin-top: 8px;
  }
}

@media (max-width: 1350px) {
  .faunderBlock {
    margin-left: 50px;
  }
  .icons {
    margin-left: 14px;
  }
}

@media (max-width: 960px) {
  .logo_x11 {
    left: 80px;
  }
}

@media (max-width: 760px) {
  .faunderBlock {
    margin-top: 32px;
    margin-left: 0;
  }
  .icons {
    display: none;
  }
}
