@use 'src/styles/globals.scss' as style;

.faunderName {
  position: absolute;
  width: 153px;
  height: 16px;
  left: 0;
  bottom: 24px;

  @include style.mainText;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: style.$secondaryText;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.faunder {
  position: relative;
  box-sizing: border-box;
  width: 281px;
  height: 500px;
  position: relative;
  background: linear-gradient(327.3deg, #f8faff 8.95%, #fcfcfd 46.23%, #f2f5fb 82.96%);
  background-size: cover;
  border: 1px solid #ffffff;
  box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.9),
    4px 4px 12px rgba(0, 16, 47, 0.12), inset 0px 0px 24px 12px #ffffff;
  border-radius: 16px;
  video {
    width: 281px;
    height: 500px;
    object-fit: cover;
    border-radius: 16px;
  }
}

.play {
  position: absolute;
  top: 186px;
  left: 77px;
  z-index: 40;
  &:hover{
    cursor: pointer;
  }
}
