@use 'src/styles/globals.scss' as style;

.blog_post_col {
  max-width: 429px;
  flex: 0 0 429px;
  border-radius: 10px;
  position: relative;
  padding: 0px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-left: 10px;
  }
}
.blog_post_col.blog_post_col_slide {
  height: 100%;
}
.blog_post_col:hover {
  box-shadow: 3px 3px 8px rgb(16 24 40 / 10%), 2px 2px 6px rgb(16 24 40 / 6%),
    inset 2px 2px 5px #ffffff;
  border-color: #fafbfd;
}

.blog_post_prewiev {
  border-radius: 5px 5px 0px 0px;
  height: 192px;
  overflow: hidden;
}
.blog_post_prewiev img {
  width: 100%;
  height: 192px;
  display: block;
  object-fit: cover;
}
.blog_post_publish_date,
.blog_post_publish_source {
  @include style.mainText;
  @include style.smallSize;
  color: #a1a6b3;
}

.source_logo img {
  width: 150px;
  display: block;
  object-fit: cover;
}

.blog_post_publish_source {
  @include style.mainSize;
  color: #484b54;
}

.blog_post_title h3 {
  margin: 0;
  @include style.mainText;
  font-weight: 700;
  @include style.largeSize;
  color: #484b54;
  padding: 15px 0;
}
.blog_post_description p {
  margin: 0;
  @include style.mainText;
  @include style.mainSize;
  color: #303239;
}
.blog_post_more {
  margin-top: 10px;
}
.blog_post_link_more {
  text-decoration: none;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px;
  box-sizing: border-box;
}
.blog_post_more {
  @include style.mainText;
  @include style.mainSize;
  font-weight: 700;
  color: #017de5;
  text-decoration: none;
  margin-bottom: 17px;
}
.blog_post_more img {
  margin-left: 10px;
  max-width: 10px;
}

.spinner_wrap {
  min-height: 56vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinner {
  width: 50px;
  height: 50px;
  margin-right: 4px;
  display: inline-block;
  border: 3px solid #017de5;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin 1s infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: #69bafe;
    border-bottom-color: #69bafe;
    border-right-color: transparent;
    border-left-color: transparent;
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1199px) {
  .latest_blog_post_title {
    margin-bottom: 10px;
  }
  .blog_post_more {
    margin-top: 18px;
  }
}

@media (max-width: 999px) {
  .blog_post_prewiev {
    border-radius: 5px 5px 0px 0px;
    object-fit: cover;
    height: 192px;
    overflow: hidden;
  }
  .latest_blog_post_title {
    margin-bottom: 21px;
  }
  .blog_post_title {
    margin-bottom: 10px;
  }
  .blog_post_more {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .blog_post_title {
    margin-bottom: 32px;
  }
  .blog_post_more {
    margin-top: 30px;
  }
  .blog_post_col {
    width: 100%;
    padding-bottom: 0;
  }
  .latest_blog_post_title {
    font-size: 18px;
    line-height: 120%;
  }
  .blog_post_title {
    font-size: 18px;
    line-height: 120%;
  }
  .blog_post_prewiev img {
    max-width: 429px;
  }
}
@media (max-width: 479px) {
  .blog_post_col {
    max-width: 100%;
    flex: none;
  }
  .blog_post_title {
    margin-bottom: 8px;
  }
  .blog_post_more {
    margin-top: 17px;
  }
  .blog_post_prewiev img {
    max-width: 100%;
  }
}
