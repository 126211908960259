.App {
  background: #F4F6FB;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.bgImage {
  position: absolute;
  top: 0;
  right: 0;
  height: 605.82px;
  width: 532.4px;
}

.container {
  width: 1440px;
  margin: 0 auto;
}

@media (max-width: 1350px) {
  .container {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
  }
}

@media (max-width: 960px) {
  .bgImage {
    height: 544.3px;
    width: 478px;
  }
}

@media (max-width: 440px) {
  .bgImage {
    height: 445.25px;
    width: 391px;
  }
}
