@use 'src/styles/globals.scss' as style;

.container {
  position: relative;
  @include style.leftPadding;
  padding-bottom: 200px;
}

.legalAspect {
  margin-left: 493px;
  @include style.position;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.legalItems {
  margin-top: 37px;
  @include style.blockStyle;
}

a {
  text-decoration: none;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  &:not(:first-child) {
    margin-top: 32px;
  }

  p {
    margin: 0;
    padding-left: 16px;
    @include style.mainText;
    @include style.mainText;
    font-size: 16px;
    line-height: 24px;
    color: style.$blackText;
  }
}

.bgText {
  left: -9px;
  top: 42px;
  @include style.bgText;
}

.textAminate {
  z-index: 5;
  animation: textPosition 2s ease-in;
}

.checkBlock {
  position: relative;
  z-index: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 56px 56px;
  box-sizing: border-box;
  width: 664px;
  height: 100%;

  background: #f4f6fb;

  box-shadow: 0px 32px 64px rgba(16, 24, 40, 0.14);
  border-radius: 12px;
  margin-left: -16px;
  margin-top: -24px;
}

.checkBlockAnimate {
  animation: fade 1.5s ease-in;
}

.checkHeader {
  margin: 0;
  @include style.mainText;
  @include style.largeSize;
  font-weight: 700;
  color: #595d67;
}

.checkItems {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;
  box-sizing: border-box;
  width: 127px;
  height: 112px;

  background: linear-gradient(
    327.3deg,
    #f8faff 8.95%,
    #fcfcfd 46.23%,
    #f2f5fb 82.96%
  );
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(0, 16, 47, 0.15),
    inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 4px 4px 8px #ffffff;
  border-radius: 24px;

  &:hover {
    cursor: pointer;
    box-shadow: -2px -2px 8px #ffffff, 1px 1px 6px rgba(0, 16, 47, 0.23),
      inset -2px -2px 4px rgba(0, 0, 0, 0.11), inset 1px 1px 6px #ffffff;
  }
  &:active {
    box-shadow: 4px 4px 12px #ffffff, -1px -1px 9px rgba(0, 16, 47, 0.11),
      inset 4px 4px 8px rgba(0, 0, 0, 0.06), inset -4px -4px 8px #ffffff;
  }
}

.checkImage {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;

  border: 1px solid #ffffff;
  border-radius: 111px;
}

.check p {
  margin: 0;
  font-family: style.$Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  color: #232428;
}

@keyframes textPosition {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes fade {
  0% {
    transform: perspective(1000px) rotateX(90deg) translateY(-150px);
    opacity: 0;
  }
  100% {
    transform: perspective(1000px) rotateX(0deg) translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .legalAspect {
    margin-left: calc(100% - 664px);
  }
}

@media (max-width: 960px) {
  .container {
    padding-bottom: 124px;
  }
  .legalAspect {
    margin-left: 0;
  }
  .bgText {
    left: -54px;
    top: 20px;
  }
  .legalItems {
    margin-top: 56px;
  }
}

@media (max-width: 740px) {
  .checkBlockAnimate,
  .textAminate {
    animation: none;
  }
  .bgText {
    left: 18px;
    top: 40px;
  }
  .legalItems {
    width: 327px;
    padding: 24px 16px;
  }
  .legalItems {
    width: 100%;
  }
  .checkBlock {
    width: calc(100% + 24px);
    padding: 24px 14.5px;
    margin-left: -12px;
  }
  .check p {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 460px) {
  .container {
    padding-bottom: 96px;
  }
  .check {
    width: 106px;
    &:not(:nth-child(2)) {
      width: 96px;
    }
  }
}
