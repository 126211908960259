@use 'src/styles/globals.scss' as style;

.container {
  position: relative;
  @include style.leftPadding;
  padding-bottom: 200px;
}

.ecosystem {
  @include style.position;
}

.descriptions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.arrow {
  position: absolute;
  right: -48px;
  top: 37px;
}

.textBlock {
  position: relative;
  width: 698px;
  margin-top: 64px;
  p {
    margin: 0;
    @include style.mainText;
    @include style.smallSize;
    color: #484B54;
  }
  h3 {
    margin: 10px 0;
    @include style.mainText;
    @include style.mainSize;
    font-weight: 600;
    color: #484B54;
  }
}

.whitepaperBlock {
  width: 220px;
  margin-left: 494px;
  margin-top: 44px;
  align-self: flex-end;
  p {
    font-weight: 700;
  }
}

.faunderContainer {
  margin-left: 105px;
  margin-top: -56px;
}

.faunderAminate {
  z-index: 5;
  animation: faunderPosition 0.5s ease-in;
}

.whitepaperAnimate {
  animation: whitepaperPosition 0.5s ease-in;
}

.formBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.linksBlock {
  margin-top: 79px;
  margin-left: 93px;
}

@keyframes faunderPosition{
  0% {
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes whitepaperPosition{
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 1150px) {
  .textBlock {
    width: calc(100% - 386px);
  }
  .whitepaperBlock {
    margin-left: 0;
    width: 308px;
  }
}

@media (max-width: 960px) {
  .textBlock,
  .faunderContainer {
    margin-top: 40px;
  }
  .textBlock {
    width: 348px;
  }
  .faunderContainer {
    margin-left: 50px;
  }
  .whitepaperBlock {
    margin-top: 56px;
  }
  .arrow {
    display: none;
  }
  .formBlock {
    flex-direction: row;
    margin-top: 56px;
    align-items: center;
  }
  .linksBlock {
    margin-left: 121px;
    margin-top: 0;
  }
  .container {
    padding-bottom: 80px;
  }
}

@media (max-width: 700px) {
  .whitepaperAnimate, .faunderAminate {
    animation: none;
  }
  .descriptions {
    flex-direction: column;
  }
  .faunderContainer {
    margin-left: 0;
    align-self: center;
  }
  .textBlock {
    margin-top: 24px;
    width: 100%;
  }
  .whitepaperBlock {
    margin-top: 32px;
  }
  .formBlock {
    margin-top: 32px;
    justify-content: space-between;
  }
  .linksBlock {
    margin-left: 0;
  }
}

@media (max-width: 550px) {
  .linksBlock {
    display: none;
  }
}

@media (max-width: 375px) {
  .formBlock {
    display: block;
    margin-left: -12px;
  }
  .whitepaperBlock {
    width: calc(100% - 20px);
    margin: 32px 10px 0 10px;
  }
}
