@use 'src/styles/globals.scss' as style;

.container {
  position: relative;
  @include style.leftPadding;
  padding-bottom: 200px;
}

.faqBlock {
  @include style.position;
}

.questions {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 988px;
}

.questionsBlock:last-child {
  margin-left: 24px;
}

.questionBlock,
.questionBlockOpen {
  width: 469px;
  margin-bottom: 32px;
  padding: 16px 16px 16px 8px;
  box-sizing: border-box;
  background: #f4f6fb;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.11), inset 1px 1px 0px #ffffff;
  border-radius: 4px;
}

.questionBlockOpen {
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.11);
}

.questionBlock:hover {
  cursor: pointer;
  background: #fafbfd;
  box-shadow: 3px 3px 8px rgba(16, 24, 40, 0.1),
    2px 2px 6px rgba(16, 24, 40, 0.06), inset 2px 2px 5px #ffffff;
}

.questionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.questionPoint {
  @include style.mainText;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  color: #ffffff;
  margin-right: 8px;
}

.questionBlock:hover .questionPoint {
  color: #eceff5;
}

.title {
  width: 371px;
  @include style.mainText;
  font-size: 16px;
  line-height: 24px;
  color: #303239;
}

.description {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 35px;
  @include style.mainText;
  font-size: 16px;
  line-height: 24px;
  color: #303239;
  font-size: 14px;
  line-height: 24px;
  ol {
    margin: 0;
    padding-left: 15px;
  }
}

.arrow {
  margin-left: 8px;
}

@media (max-width: 1030px) {
  .container {
    padding-bottom: 80px;
  }
  .questionsBlock {
    &:last-child {
      margin-left: 0;
    }
    width: 100%;
  }
  .questions {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .questionBlock,
  .questionBlockOpen {
    width: 100%;
    margin-bottom: 16px;
  }
  .title {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .container {
    padding-bottom: 40px;
  }
  .questions {
    width: calc(100% + 48px);
    margin-left: -24px;
  }
  .questionBlock,
  .questionBlockOpen {
    padding: 16px 24px;
    background: #f4f6fb;
    border: none;
    border-bottom: 1px solid #cacedb;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    &:nth-child(4n) {
      margin-bottom: 0;
    }
  }
  .questionPoint {
    display: none;
  }
  .description {
    padding: 0;
    margin-top: 16px;
  }
}
