@use 'src/styles/globals.scss' as style;

.container {
  position: relative;
  @include style.leftPadding;
  padding-bottom: 200px;
}

.mobileBenefits {
  @include style.position;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.whitepaperBlock {
  margin-top: 24px;
}

.benefitsImg {
  width: 319px;
  margin-left: 60px;
  margin-right: 114px;
}

.benefits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &__item {
    border-left: 2px solid #f79009;
    padding: 0px 8px;
    margin-top: 32px;
    @include style.mainText;
    font-size: 16px;
    line-height: 24px;
    color: #484b54;
    p {
      margin: 0;
    }
    span {
      font-weight: 700;
    }
  }
}

@media (max-width: 960px) {
  .benefitsImg {
    width: 204px;
    margin-left: 50px;
    margin-right: 107px;
  }
  .container {
    padding-bottom: 80px;
  }
}

@media (max-width: 760px) {
  .benefitsImg {
    margin: 0;
  }
  .mobileBenefits {
    flex-direction: column;
  }
  .benefits {
    align-self: start;
  }
}
