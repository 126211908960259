@use 'src/styles/globals.scss' as style;

.bestSystemsBlock {
  position: relative;
  width: 635px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -165px;
  margin-left: 495px;
  padding-bottom: 200px;
}

.bgText {
  position: absolute;
  width: 635px;
  @include style.mainText;
  font-style: normal;
  font-weight: 700;
  font-size: 141.493px;
  line-height: 144px;
  left: 0;
  top: 0;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  // border: 5.42714px solid #FFFFFF;

  text-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.systems {
  position: relative;
  @include style.position;
  width: 363px;
  height: 504px;
  box-sizing: border-box;
  background: #eceff5;
  // backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 32px 64px rgba(16, 24, 40, 0.14);
  padding: 32px 0;
  overflow: hidden;
}

.bgTextSystems {
  position: absolute;
  left: -129px;
  top: 0;
  @include style.mainText;
  font-weight: 700;
  font-size: 139.27px;
  line-height: 142px;

  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;

  border: 5.34188px solid #FFFFFF;
  filter: blur(6px);
}

.points {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 45px;
  left: 20px;
  z-index: 5;
}

.circle {
  width: 7px;
  height: 7px;
  background: #DC6803;
  border-radius: 50%;
}

.line {
  margin: 4px 0;
  height: 281px;
  border-left: 1px solid #DC6803;
}

.systemItem {
  position: relative;
  z-index: 5;
  width: 291px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 40px;
  p {
    margin: 0;
  }
  &:last-child {
    margin-top: 88px;
  }
}

.systemHeader {
  width: 220px;
  @include style.mainText;
  @include style.largeSize;
  font-weight: 700;
  color: #DC6803;
}

.systemDescription {
  padding-top: 16px;
  @include style.mainText;
  @include style.largeSize;
  font-weight: 400;
  color: #232428;
  span {
    font-weight: 700;
  }
}

@media (max-width: 1200px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: 0;
    width: 100%;
  }
  .bestSystemsBlock {
    width: 466px;
    margin-top: 80px;
    margin-left: 0;
    margin-right: 120px;
  }
  .bgText {
    width: 466px;
    font-size: 103.865px;
    line-height: 106px;
    top: 70px;
  }
  .bgTextSystems {
    width: 425px;
    left: -31.89px;
    top: 90px;
    font-size: 94.8158px;
    line-height: 96px;
  }
  .systems {
    width: 359px;
  }
  .points {
    left: 12px;
  }
  .systemItem {
    padding-left: 36px;
  }
}

@media (max-width: 960px) {
  .container {
    justify-content: flex-start;
  }
  .bestSystemsBlock {
    margin-left: 188px;
    margin-right: 0;
    padding-bottom: 120px;
  }
}

@media (max-width: 740px) {
  .container {
    justify-content: center;
  }
  .bestSystemsBlock {
    margin-left: 0;
  }
  .systemItem {
    width: 256px;
    &:last-child {
      margin-top: 40px;
    }
  }
  .systems {
    width: 311px;
    height: 388px;
  }
  .systemHeader, .systemDescription {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 550px) {
  .bestSystemsBlock {
    width: 100%;
  }
  .bgText {
    left: -45px;
  }
  .bestSystemsBlock {
    padding-bottom: 80px;
  }
}
