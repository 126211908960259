@use 'src/styles/globals.scss' as style;

.whitepaper {
  @include style.headerItem;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  p {
    white-space: nowrap;
    font-family: style.$Ubuntu;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #232428;
    margin: 0;
    padding: 0 8px;
  }
}
