@use 'src/styles/globals.scss' as style;

.container {
  @include style.leftPadding;
  position: relative;
  padding-bottom: 200px;
  width: calc(100% + 24px);
  overflow: hidden;
}

.wrapper {
  width: 100%;
  @include style.position;
}

.roadMadControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 140px;
  .arrowRight,
  .arrowLeft {
    width: 24px;
    height: 24px;
    z-index: 5;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

.arrowRight {
  margin-left: 8px;
}

.controlDots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .activeDots {
    background: #F79009;
    @include style.colorTransition;
  }
  div {
    margin-left: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #CACEDB;
    @include style.colorTransition;
  }
}


.roadMap {
  width: 100%;
  @include style.position;
  // transition: 5s;
  position: relative;
  padding-top: 8px;
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.roadMapLine {
  position: absolute;
  top: 144px;
  width: 100%;
  border-top: 1px solid #a1a6b3;
  border-bottom: 1px solid #ffffff;
}

.playMap {
  animation: playSlow 2s ease-in;
  background: red;
}

.quarter {
  position: relative;
  width: 484px;
  margin-left: 53px;
  background: #f4f6fb;
  border-left: 1px dashed #cacedb;
  padding: 0 8px;
  box-sizing: border-box;
  @include style.mainText;
  @include style.smallSize;
  p {
    text-decoration-line: underline;
    color: #232428;
    margin: 0;
  }
  span {
    margin-top: 8px;
    @include style.mainText;
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
    color: #757985;
  }
  ol {
    margin: 0;
    padding: 0 0 0 30px;
  }
}
.quarterItem {
  margin-top: 24px;
  &:not(first-child) {
    margin-top: 0;
  }
}

.quarterPoint {
  width: 484px;
}

.quarterName {
  position: absolute;
  left: -6px;
  top: -38px;
  @include style.mainText;
  @include style.smallSize;
  text-transform: uppercase;
  color: #a1a6b3;
}

.quarterCircle {
  position: absolute;
  left: -10px;
  top: -14px;
  padding: 0 4px;
  background: #f4f6fb;

  div {
    box-sizing: border-box;
    width: 11px;
    height: 11px;
    border: 1px solid #a1a6b3;
    border-radius: 50%;
    @include style.colorTransition;
  }
}

div.active {
  background: #f79009;
  border-color: #f79009;
  @include style.colorTransition;
}

@keyframes playSlow {
  0% {
    transform: rotateX(0px);
    // transition: 2s ease-in;
  }
  100% {
    transform: rotateX(-324px);
    // transition: 2s ease-in;
  }
}

@media (max-width: 960px) {
  .container {
    padding-bottom: 150px;
  }
  .roadMap {
    width: calc(100% + 24px);
  }
  .roadMapLine {
    top: 120px;
  }
  .roadMadControl {
    display: none;
  }
  .wrapper {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
  }
  }
}

@media (max-width: 740px) {
  .roadMapLine {
    top: 96px;
  }
}

@media (max-width: 550px) {
  .quarter {
    margin-left: 36px;
    &:first-child {
      margin-left: 0;
    }
  }
  .wrapper {
    margin-left: -24px;
    width: calc(100% + 48px);
  }
  .roadMap {
    margin-left: 24px;
  }
  .quarterPoint {
    width: 256px;
  }
  .container {
    overflow: visible;
    padding-bottom: 64px;
  }
}
