@use 'src/styles/globals.scss' as style;

.header {
  width: 1216px;
  margin-top: 12px;
  margin-bottom: 32px;
  @include style.leftPadding;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerButtons {
  display: flex;
}

.item,
.language {
  margin-left: 24px;
  max-width: 120px;
  @include style.headerItem;
  text-decoration: none;
  p {
    white-space: nowrap;
    font-family: style.$Ubuntu;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #232428;
    margin: 0;
    padding: 0 8px;
  }
}

.language {
  p {
    width: 25px;
  }
}

@media (max-width: 1350px) {
  .header {
    width: 100%;
  }
}

@media (max-width: 960px) {
  .item,
  .language {
    margin-left: 8px;
  }

}

@media (max-width: 480px) {

  .header {
    flex-direction: column;
  }
}
